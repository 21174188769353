<template>
    <div id="app">
        <icon-sprite />
        <router-view />
    </div>
</template>

<script>
import IconSprite from '@/components/shared/IconSprite.vue';


export default {
    components: {
        IconSprite,
    },
};


</script>

<style lang="scss">

#app {
    color: $gray-dark;
}
</style>
