<template>
    <section class="age-check">
        <h1>{{ title }}</h1>

        <div class="age-check__choice">
            <a class="age-check__button button" v-on:click="changeIntro('SearchBox')">Ja</a>
            <a href="https://www.bierbewusstgeniessen.de" target="_blank" class="age-check__button button">Nein</a>
        </div>

        <div class="intro-copy">
            <p>
                Der Zäpflefinder von Rothaus enthält Werbeinformationen zu alkoholischen Getränken.
                Als Hersteller von Qualitätsbieren tragen wir Verantwortung und setzen uns im Rahmen
                der gesetzlichen Bestimmungen für den verantwortungsbewussten Umgang mit
                alkoholischen Getränken ein. Daher bitten wir dich anzugeben, ob du über 16 Jahre alt
                bist.
            </p>
        </div>

        <div class="age-check__images">
            <a href="https://www.bierbewusstgeniessen.de" target="_blank">
                <img alt="Bier Bewusst Geniessen" src="@/assets/images/logo-bierbewusst.png" height="100" width="100" />
            </a>
            <a href="https://ddad.de/" target="_blank">
                <img alt="" src="@/assets/images/logo-ddad.png" width="98" height="100" />
            </a>
        </div>

    </section>
</template>

<script>
export default {
    name: 'AgeCheck',
    props: {
        title: String,
    },
    methods: {
        changeIntro(value) {
            this.$parent.introContent = value;
        },
    },
};
</script>

<style lang="scss" scoped>


    h1 {
        font-size: rem(40px);
        @include screen('sm-min') {
            font-size: rem(60px);
        }
    }

.age-check__choice {
    display: flex;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
}

.age-check__button {
    flex: 1;
    font-size: rem(30px);
    padding: .5em;

    &:not(:last-child) {
        margin-right: 1rem;
    }
}

.age-check__images {
    margin-top: 30px;

    img {
        margin-left: 10px;
        margin-right: 10px;
    }
}

</style>
