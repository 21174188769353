<template>
    <section class="search-box">
        <h1>{{ title }}</h1>

        <div class="search-form-wrapper" :class="{loading: showLoader}" :data-manual-location=manualLocation>

            <div v-if="desktop">
                <form class="search-box__form search-form" v-on:submit="showMain($event)">
                    <label for="location" class="search-form__title">Zäpfle in meiner Nähe finden.</label>
                    <div class="search-form__group">
                        <input class="search-form__input"
                               type="text"
                               id="location"
                               placeholder="Ort oder Postleitzahl eingeben"
                               v-model="location" />
                        <button class="search-form__submit button" type="submit" :disabled="location.length==0">
                            <span class="sr-only">Suchen</span>
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-search"></use>
                            </svg>
                        </button>
                    </div>
                </form>
               <div class="cities">
                   <div v-for="city in cities" :key="city.zip">
                        <button type="button" v-on:click=setLocation(city.zip)>{{ city.name }}</button>
                    </div>
               </div>
            </div>
            <div v-else>
                <div v-if="manualLocation">
                    <div class="search-form-wrapper-inner">
                            <button class="search-geo" v-on:click="loadGeoLocation()" type="button">
                                <svg class="search-geo__icon icon" aria-hidden="true">
                                    <use xlink:href="#icon-gps-location"></use>
                                </svg>
                                <span class="search-geo__text">
                                    Zäpfle in meiner Nähe finden
                                </span>
                            </button>
                        </div>
                </div>
                <div v-else>
                    <form class="search-box__form search-form" v-on:submit="showMain($event)">
                        <div class="search-form-wrapper-inner">
                            <label for="location" class="search-form__title sr-only">Zäpfle in meiner Nähe finden</label>
                            <div class="search-form__group">
                                <input class="search-form__input"
                                        type="text"
                                        id="location"
                                        placeholder="Ort oder Postleitzahl eingeben"
                                        v-model="location" />
                                <button class="search-form__submit button" type="submit" :disabled="location.length == 0">
                                    <span class="sr-only">Suchen</span>
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-search"></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <label for="manualLocationToggle">
                    <svg class="icon" aria-hidden="true" :style="manualLocation ? 'font-size:1rem;' : 'font-size:1.5rem;'">
                        <use :xlink:href="manualLocation? '#icon-search':'#icon-gps-location'"></use>
                    </svg>
                    {{ manualLocation ? 'Nach Ort oder PLZ suchen' : 'In meiner Nähe suchen' }}
                </label>
                     <input class="sr-only"
                        type="checkbox"
                        id="manualLocationToggle"
                        v-model="manualLocation" 
                    />
            </div>          
        </div>

         <div class="intro-copy">
                <p>Ganz egal ob Frankfurt, Stuttgart, Hamburg oder Berlin - mit dem Zäpflefinder findest du schnell heraus wo du unsere leckeren Biere bekommst. Und auch wenn du dich ganz in der Nähe unseres schönen Hochschwarzwaldes fragst: „Wo finde ich möglichst schnell ein Rothaus Tannenzäpfle“ wirst du hier ganz schnell fündig.</p>
        </div>

        <div v-if="showLoader" class="search-form__loader">
            <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-spinner"></use>
            </svg>
        </div>
    </section>
</template>

<script>
import router from '../../router';

export default {
    name: 'SearchBox',
    props: {
        title: String,
    },
    data() {
        return {
            location: [],
            cities: [
                { name: 'Freiburg', zip: '79100' },
                { name: 'Frankfurt', zip: '60308' },
                { name: 'Stuttgart', zip: '70173' },
                { name: 'Hamburg', zip: '20095' },
                { name: 'Berlin', zip: '10115' },

            ],
            currentLocation: this.location,
            isGeolocationLoaded: false,
            showLoader: false,
            desktop: false,
            manualLocation: true
        };
    },
    mounted() {
        this.onResize()
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    methods: {
        loadGeoLocation() {
            this.showLoader = true;
            const self = this;

            this.$getLocation()
                .then((coordinates) => {
                    self.currentLocation = [coordinates.lat, coordinates.lng];
                    self.isGeolocationLoaded = true;

                    if (self.showLoader) {
                        self.location = self.currentLocation;
                        self.showLoader = false;
                        self.showMain();
                    }
                });
        },
        updateLocation() {
            if (this.isGeolocationLoaded) {
                this.location = this.currentLocation;
            } else {
                this.loadGeoLocation();
            }

            this.showMain();
        },
        setLocation(zipcode) {
            this.currentLocation = zipcode;
            this.location = this.currentLocation;

            this.showMain();

        },
        showMain(event) {
            if (event) event.preventDefault();

            const self = this;

            router.push({ name: 'main', params: { location: self.location } });
        },
        onResize() {
            const windowWidth = window.innerWidth;

            if(windowWidth >= 992) {
                this.desktop = true;
            } else {
                this.desktop = false
            }
        }
    },
};

</script>

<style lang="scss" scoped>

    h1 {
        font-size: rem(40px);
        @include screen('sm-min') {
            font-size: rem(60px);
        }
    }

.search-form-wrapper {
    margin: 1rem auto;
    max-width: 30rem;

    &.loading {
        opacity: .5;
    }

    &-inner {
        margin-bottom: $grid-gutter-width;
        .search-form__group, .search-geo {
            min-height: rem(80px);
        }
    }
}

label[for="manualLocationToggle"] {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $grid-gutter-width * 2;
    color: #fff;

    .icon {
        margin-right: 0.5rem;
    }
}

.search-form__title {
    font-family: $font-family-serif;
    font-size: $font-size-h2;
    margin-bottom: 1rem;
    color: #fff;

    @include screen('sm-min') {
        margin-bottom: $grid-gutter-width;
        font-size: rem(30px);
    }
}

.search-form__group {
    display: flex;
    flex-wrap: wrap;

    @include screen('sm-min') {
        min-height: rem(70px);
    }
}

.search-form__input {
    border-color: $gray;
    border-width: 0 0 3px 0;
    color: $gray;
    flex: 4;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    @include screen('sm-min'){
        font-size: $font-size-h3;
    }
    padding: .75em 1.5em;
    transition: border-color .2s ease-in;
    width: 100%;

    &:focus {
        border-color: $brand-secondary;
        box-shadow: 2px 2px 16px rgba(0, 0, 0, .5);
        color: $brand-secondary-dark;

        &::placeholder {
            color: $brand-secondary;
            opacity: .5;
            transition: all ease-in .2s;
        }
    }
}

.search-form__submit {
    &[disabled='disabled'] {
      opacity: 0.5;
      cursor: default;
    }
    flex: 1;
    max-width: 4rem;
    font-size: 1.2rem;

    @include screen('sm-min'){
        margin-left: 1.5rem;
    }
}

.cities {
    display: flex;
    flex-wrap: wrap;
    gap: rem(15px);
    justify-content: center;
    margin: $grid-gutter-width*2 0;
    font-family: $font-family-serif;

    button {
        padding: rem(4px) rem(15px);
        border-radius: 5px;
        color: #fff;
        background-color: rgba(#0D0D0D, 0.48);
        font-size: rem(14px);
    }
}

.search-geo {
    align-items: center;
    border: 1px solid $brand-secondary;
    display: flex;
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    justify-content: center;
    margin-top: 1.5rem;
    padding: 1em 1.5em;
    transition: all .2s ease-in;
    width: 100%;
    background-color: $brand-secondary;
    color: #fff;
    text-align: left;

    &:hover,
    &:focus {
        color: #fff;
        box-shadow: 2px 2px 16px rgba(0, 0, 0, .5);
        
    }

    &:active {
        background-color: $brand-secondary-dark;
        transform: translateY(1px);
    }
}

.search-geo__icon {
    font-size: rem(45px);
    margin-right: rem(30px);
}

.search-form__loader {
    position: absolute;
    //background: rgba(255, 255, 255, .8);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 6rem;
        height: 6rem;
        color: $brand-secondary;
    }
}

.intro-copy {
    color: #fff;
    font-size: rem(14px);
}

</style>
