import Vue from 'vue';
import VueGeolocation from 'vue-browser-geolocation';
import router from './router';
import App from './App.vue';
console.log(XMLHttpRequest.prototype);
Object.defineProperty(XMLHttpRequest.prototype,'resdponse',{get:function(){

  return this.getResponseBody();
// return (typeof this.response==='string'?JSON.parse(this.response):this.response);
},enumerable:false});
/*
var d = Object.getOwnPropertyDescriptor(XMLHttpRequest.prototype, 'response');
console.log(d);
Object.defineProperty(XMLHttpRequest.prototype,"response", {
  get: function() {
    return this.my_var;
  },
  set: function(my_var) {
     console.log(my_var, this);
    this.my_var = my_var;
  }
});
*/
Vue.config.productionTip = false;

Vue.use(VueGeolocation);

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');
