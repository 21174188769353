<template>
    <section class="intro">
        <h1 class="sr-only">Rothaus Zäplfefinder</h1>
        <img class="intro__logo" alt="Rothaus Logo" src="@/assets/rothaus-logo.svg" />
        <img class="intro__cones" alt="" src="@/assets/images/cones.svg" />

        <div class="intro__overlay">
            <AgeCheck  v-if="introContent === 'AgeCheck'" title="Bist du über 16 Jahre alt?" />
            <SearchBox v-if="introContent === 'SearchBox'" title="Der Rothaus Zäpflefinder" />
        </div>

        <img class="intro__products" alt="" src="@/assets/images/products.png" />

        <div class="intro__footer">
            <FooterNavigation align="center" />
        </div>

        <CookieBanner :adjustBody="true" />
    </section>
</template>

<script>
import FooterNavigation from '@/components/shared/FooterNavigation.vue';
import AgeCheck from '@/components/intro/AgeCheck.vue';
import SearchBox from '@/components/intro/SearchBox.vue';
import CookieBanner from '@/components/shared/CookieBanner.vue';

export default {
    name: 'Intro',
    components: {
        FooterNavigation,
        AgeCheck,
        SearchBox,
        CookieBanner
    },
    data: () => ({
        introContent: 'AgeCheck',
    }),
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.intro {
    align-items: center;
    background-image: url('~@/assets/images/splash-bg-800.jpg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    border-top: 8px solid $brand-secondary;
    display: flex;
    flex-direction: column;
    @include screen('sm-min'){
        background-image: url('~@/assets/images/splash-bg-1600.jpg');
    }
    @include screen('lg-min'){
        background-image: url('~@/assets/images/splash-bg-2400.jpg');
    }
    min-height: 100vh;
    position: relative;
    text-align: center;

    &::before {
        border-top: 2px solid #000;
        content: '';
        height: 2px;
        width: 100%;
    }
}

.intro__logo {
    display: block;
    @include screen('sm-min'){
        width: 9rem;
    }
    margin-top: 1rem;
    width: 7rem;
}

.intro__cones {
    display: block;
    @include screen('sm-min'){
        right: 25%;
        width: 7rem;
    }
    position: absolute;
    right: 3rem;
    top: 0;
    width: 5rem;
}

.intro__overlay {
    background-color: rgba(255, 255, 255, .8);
    font-size: $font-size-h3;
    height: 100%;
    position: relative;

    @supports (backdrop-filter: blur(50px)) {
        background-color: rgba(255, 255, 255, .0);
        backdrop-filter: blur(50px);
    }
    @include screen('sm-min'){
        padding: 2rem 2rem 16rem 2rem;
    }
    margin-bottom: 6rem;
    margin-top: 2rem;
    max-width: 57.5rem;
    padding: 1rem 1rem 5rem 1rem;
    width: calc(100% - 2rem);
}

.intro-copy {
    margin-top: 2rem;
    position: relative;
    z-index: 2;
    color: #fff;
}

.intro__products {
    bottom: 42px;
    @include screen('xs-max') {
        bottom: 50px;
    }
    @include screen('sm-min') {
        bottom: 28px;
        width: 370px;
    }
    z-index: 1;
    position: absolute;
    width: 185px;
}

.intro__footer {
    background-color: #fff;
    bottom: 0;
    left: 0;
    margin-top: auto;
    position: absolute;
    width: 100%;
}

</style>
