<template>
<div  class="cookie-note" v-if="show">
    <div class="container">
        <button class="button" @click="accept">Ok</button>
        <p>Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in unserer
        <a href="https://www.rothaus.de/de/hinweise/datenschutz" target="blank">Datenschutzerklärung</a></p>
    </div>
</div>
</template>

<script>

export default {
    name: 'CookieBanner',
     props: {
      'adjustBody': Boolean
     },
     data: () => ({
        show: false
    }),
  mounted: function() {
    if (this.getCookie("cookieaccept") != 'true') {
      this.show = true;
      let self = this;
      setTimeout(function() {
        self.resizeCookie();
      }, 1000);

    }
  },
  methods: {
    accept: function() {
      this.show = false;
      let body = document.querySelector('body');
      body.setAttribute('style', 'padding-bottom: ' + 0 + 'px');
      document.cookie = "cookieaccept=true;path=/";
    },
    resizeCookie: function() {
      if (this.adjustBody) {
        let el = document.querySelector('.cookie-note:not(.hidden)');
        if (el) {
          let height = parseFloat(getComputedStyle(el, null).height);
          let body = document.querySelector('body');
          body.setAttribute('style', 'padding-bottom: ' + height + 'px');
        }
      }
    },

    getCookie: function(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.cookie-note {
  z-index: 1002;
  width: 100%;
  background: black;
  color: white;
  bottom: 0px;
  left: 0px;
  position: fixed;
  padding: 10px;
  p {
    text-align: justify;
    margin-bottom: 0px;
  }
  a {
    color: white;
    text-decoration: underline;
  }
}

.button {
  background: #da020d;
  float: right;
  margin-left: 10px;
}
</style>
