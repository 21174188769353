<template>
    <nav class="container footer-navigation">
        <ul class="footer-menu" :class="`footer-menu--align-${align}`">
            <li class="footer-menu__item footer-menu__copy">
                <span class="footer-menu__pseudo-link"> © 2019 Badische Staatsbrauerei Rothaus AG. Alle Rechte vorbehalten </span>
            </li>
            <li class="footer-menu__item footer-menu__home-link">
                <a class="footer-menu__link" target="_blank" href="https://www.rothaus.de">
                    zur Rothaus Website
                </a>
            </li>
            <li class="footer-menu__item">
                <a class="footer-menu__link" target="_blank" href="https://www.rothaus.de/de/impressum">
                    Impressum
                </a>
            </li>
            <li class="footer-menu__item">
                <a class="footer-menu__link" target="_blank" href="https://www.rothaus.de/de/hinweise/datenschutz">
                    Datenschutz
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'FooterNavigation',
    props: {
        align: String,
    },
};
</script>

<style lang="scss" scoped>

    .footer-navigation {
        background-color: #fff;
        margin-top: auto;
        max-width: 100vw;
        padding: .5rem 0;
        font-family: $font-family-serif;

        @include screen('xs-min') {
            padding: 1.9rem 0 0.5rem 0;
        }

        @include screen('sm-max') {
            position: absolute;
            z-index: 900;
            left: 0;
            bottom: 0;
        }

        @include screen('xs-h-max') {
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }


    .footer-menu {
        @include screen('xs-min') {
            display: flex;

        }
        @include screen('xs-max') {
            li {
                display: inline-block;

                &:first-child {
                    display: block;

                    .footer-menu__pseudo-link {
                        border: none;
                    }
                }
            }
        }
    }

    .footer-menu--align-right {
        justify-content: flex-end;
    }

    .footer-menu--align-center {
        justify-content: center;
    }

    .footer-menu__link,
    .footer-menu__pseudo-link {
        color: $gray-dark;
        display: block;
        padding: 0 .5rem;

        .footer-menu__item:not(:last-child) & {
            border-right: 1px solid $gray-light;
        }
    }

    .footer-menu__link {
        text-decoration: underline;
    }

</style>
